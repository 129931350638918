// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,

  // tokenUrl: 'https://portal-api.openzstore.work',
  // apiUrl: 'https://portal-api.openzstore.work/api',

  tokenUrl: 'https://motosuccess-api.openzstore.work',
  apiUrl: 'https://motosuccess-api.openzstore.work/api',

  // tokenUrl: 'https://kofini-api.openzstore.work',
  // apiUrl: 'https://kofini-api.openzstore.work/api',

  // tokenUrl: 'https://eoda-api.openzstore.work',
  // apiUrl: 'https://eoda-api.openzstore.work/api',

  //  tokenUrl: 'https://eod-api.openzstore.work',
  //  apiUrl: 'https://eod-api.openzstore.work/api',

  // tokenUrl: 'http://localhost:5996',
  // apiUrl: 'http://localhost:5996/api',

  IdleTimeout: 10000000,

  EncryptKey: 1203199320052021,
  EncryptIV: 1203199320052021,

  // Kofini
  //  Menu: [
  //    "Dashboard",
  //    "Kofini"
  //  ],
  //  MyOrderShowInDashboard: true,
  //  MyShowOnlyTotalBalances: true,
  //  Logo: "kofini",
  //  MyCustomerContactEmailsModify: true,

  // nxs
  // Menu: [
  //   "Dashboard",
  //   "Security",
  //   "Configurator",
  //   "Marketing",
  //   "My",
  //   "MyMettings",
  //   "MyWarehouse",
  // ],

  // eoda
  // Menu: [
  //   "Dashboard",
  //   "Settings",
  //   "Security",
  //   "Notifications",
  //   "Eoda",
  //   "MyWarehouse",
  // ],

  // eod
  //  Menu: [
  //    "Dashboard",
  //    "Security",
  //    "Eod",
  //  ],

  // motosuccess
  Menu: [
    "Dashboard",
    "Security",
    "My",
    "MyWarehouse",
  ],

  MyOrderShowInDashboard: false,
  MyShowOnlyTotalBalances: false,
  MyCustomerContactEmailsModify: false,

  //! TwoWayAuth
  TwoWayAuth: false,

  // Logo: "eod"
  // Logo: "eoda",
  // Logo: "nxs",
  Logo: "motosuccess",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
